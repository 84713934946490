import React from 'react';
import ReactDOM from 'react-dom';
import { Linktree, CHANNEL_TYPES } from 'linkees';

import './css/normalize.css';

const items = [
  {
    title: 'Website',
    subtitle: 'Conheça o meu trabalho!',
    type: CHANNEL_TYPES.WEBSITE,
    link: '', //Link do website
  },
  {
    title: 'Instagram',
    subtitle: '@dtechsport |  Fique por dentro das novidades do meu trabalho! 🙂',
    type: CHANNEL_TYPES.INSTAGRAM,
    link: 'https://www.instagram.com/dtechsport/', //Link do instagram
  },
  {
    title: 'Twitter',
    subtitle: '@ | 😉',
    type: CHANNEL_TYPES.TWITTER,
    link: '', // twitter profile link
  },
  {
    title: 'LinkedIn',
    subtitle: ' Saiba mais sobre a minha empresa! 👨‍💼',
    type: CHANNEL_TYPES.LINKEDIN,
    link: '', // linkedin
  },
  {
    title: 'YouTube',
    subtitle: ' | Canal oficial da minha loja!',
    type: CHANNEL_TYPES.YOUTUBE,
    link: '', //youtube link
  },
  {
    title: 'Telegram',
    subtitle: '@D-TechSport | Entre em contato! ',
    type: CHANNEL_TYPES.TELEGRAM,
    link: '', //Telegram Pofile
  },
];

ReactDOM.render(
  <React.StrictMode>
    <Linktree cardItems={items} />
  </React.StrictMode>,
  document.getElementById('root')
);
